<template>
  <svg width="160" height="160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x=".994" width="159.006" height="160" rx="79.503" fill="#FEF8FA" />
    <path
      d="M40.887 39.04h24.539m-8.661 9.358v17.277l-7.217-4.319V48.398h7.217zM68.313 36.16L56.765 48.398h-7.217L38 36.16V34h30.313v2.16z"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M86.425 78.492c.837-2.579 7.843-8.23 7.843-8.23s2.345 8.69 1.507 11.268a4.917 4.917 0 0 1-9.35-3.038z"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M112.875 65.703l-30.713-9.98-11.103 34.172 30.713 9.98 11.103-34.172z"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M119.297 57.96L81.516 45.686c-2.675-.87-5.469.336-6.235 2.695L62.04 89.132c-2.64 8.126 2.7 17.144 11.927 20.142l.13.042 2.025-6.229c.24-.742 1.116-1.119 1.955-.847l10.286 3.342c.838.273 1.325 1.092 1.084 1.835l-2.025 6.229.599.194c9.227 2.998 18.848-1.158 21.488-9.285l13.241-40.752c.774-2.356-.771-4.97-3.453-5.842z"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      stroke="#001965"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M78.244 106.795l7.443 2.418-6.45 19.849-7.442-2.418z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgNoSmbg',
}
</script>

<style scoped></style>
